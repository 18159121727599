.product {
    display: flex;
    border-bottom: 1px solid lightgray;
    margin-bottom: 5px;
    margin-right: 5px;
    padding-bottom: 5px;
}
.product h4 {
    color: blue;
    font-weight: 400;
}
.btn-cart {
    background-color: goldenrod;
    border: 1px solid #8f6800;
    width: 150px;
    height: 25px;
    cursor: pointer;
}
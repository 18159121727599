.header img {
    height: 80px;
}
.header {
    text-align: center;
}
.header nav {
    background-color: #000;
    line-height: 40px;
    text-align: left;
    padding-left: 50px;
}
.header nav a {
    text-decoration: none;
    margin-right: 20px;
    padding: 8px;
    color: #fff;
}
.header nav a:hover {
    background-color: gray;
}
